(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
let $ = require('jquery');
jQuery = $;
require('jquery-ui/datepicker');
require('./floatlabel.js');
require('./MoneyMask.js');
require('../lib/bootstrap/dist/js/bootstrap.js');

$(function(){
	$(".floatlabel").floatlabel();
	$(".datepicker").datepicker();
	$(".moneyMask").maskMoney();
})
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_970e7206.js","/")